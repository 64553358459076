import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { en } from 'vuetify/locale';
import { aliases, md } from 'vuetify/iconsets/md';
import assets from '@/shared/services/assets';
import * as sharedComponents from '@/shared/components';
import { singleSelectStrategy } from '@/shared/services/list-select-strategies';
import { VStepperVertical, VStepperVerticalItem } from 'vuetify/labs/VStepperVertical';

export default createVuetify({
  components: {
    ...sharedComponents,
    VStepperVertical,
    VStepperVerticalItem,
  },

  defaults: {
    VCard: {
      variant: 'flat',
    },
    VCardActions: {
      VBtn: {
        variant: 'elevated',
        slim: false,
      },
    },
    VCheckboxBtn: {
      color: 'primary',
    },
    VChip: {
      variant: 'flat',
    },
    VDataTableFooter: {
      VSelect: { itemTitle: 'title' },
      VPagination: { showFirstLastPage: true },
    },
    VDataTableServer: {
      VCheckbox: {
        color: 'grey',
      },
      VCheckboxBtn: {
        color: 'grey',
      },
      showCurrentPage: true,
    },
    VPagination: {
      activeColor: 'primary',
      variant: 'flat',
    },
    VSelect: {
      itemTitle: 'text',
      menuIcon: 'expand_more',
      menuProps: {
        location: 'top',
        origin: 'overlap',
      },
    },
    VAutocomplete: {
      itemTitle: 'text',
      menuIcon: 'expand_more',
    },
    VCombobox: {
      itemTitle: 'text',
      menuIcon: 'expand_more',
    },
    VOtpInput: {
      type: 'text',
    },
    VProgressLinear: {
      color: 'primary',
    },
    VSwitch: {
      color: 'primary',
    },
    VTabs: {
      color: 'primary',
      sliderColor: 'primary',
    },
    VTextField: {
      bgColor: 'surface-light',
      color: 'primary',
    },
    VTextarea: {
      bgColor: 'surface-light',
      color: 'primary',
    },
    VToolbar: {
      color: 'surface',
      VBtn: {
        variant: 'flat',
      },
    },
    VStepperItem: {
      color: 'primary',
    },
    VList: {
      selectStrategy: singleSelectStrategy,
    },
  },

  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    },
  },

  locale: {
    messages: {
      en: {
        ...en,
        input: {
          ...en.input,
          otp: 'Enter code digit {0}',
        },
      },
    },
  },

  theme: {
    themes: {
      light: {
        colors: assets.assets.colors,
      },
    },
    variations: {
      colors: ['primary'],
      lighten: 3,
      darken: 0,
    },
  },
});
