import axios from 'axios';
import { handleError } from '@/shared/services/api';

import familySubsidyProgram from '@/shared/services/api/public/family-subsidy-program';
import grantProgram from '@/shared/services/api/public/grant-program';
import question from '@/shared/services/api/public/question';
import subsidyProgram from '@/shared/services/api/public/subsidy-program';

export default {
  assertion: {
    baseUrl() {
      return `${window.api_url}/public/assertions`;
    },
    bulkCreate(assertions, success, failure) {
      axios.post(this.baseUrl(), { assertions }).then(success).catch(failure);
    },
    create(assertion, success, failure) {
      const errorHandler = failure || handleError;
      return axios.post(this.baseUrl(), { assertion }).then(success).catch(errorHandler);
    },
    promiseBulkCreate(assertions) {
      return axios.post(this.baseUrl(), { assertions });
    },

    promiseCreate(assertion) {
      return axios.post(this.baseUrl(), { assertion });
    },
  },

  organization: {
    assertion: {
      baseUrl() {
        return `${window.api_url}/public/assertions`;
      },

      bulkCreate(assertions, success, failure) {
        axios.post(this.baseUrl(), { assertions }).then(success).catch(failure);
      },

      create(assertion, success, failure) {
        axios.post(this.baseUrl(), { assertion }).then(success).catch(failure);
      },

      promiseBulkCreate(assertions) {
        return axios.post(this.baseUrl(), { assertions });
      },

      promiseCreate(assertion) {
        return axios.post(this.baseUrl(), { assertion });
      },
    },

    assignment: {
      baseUrl(id) {
        let url = `${window.api_url}/public/organizations/${window.organization_id}/assignments`;
        if (id) url += `/${id}`;
        return url;
      },

      get(id, success) {
        axios.get(this.baseUrl(id)).then(success);
      },

      index(params, success) {
        if (success) {
          return axios.get(this.baseUrl(), { params }).then(success);
        }

        return axios.get(this.baseUrl(), { params });
      },
    },

    business: {
      baseUrl() {
        return `${window.api_url}/public/organizations/${window.organization_id}/businesses`;
      },

      get(id, success) {
        axios.get(`${this.baseUrl()}/${id}`).then(success);
      },

      index(params, success) {
        axios.get(this.baseUrl(), { params }).then(success);
      },
    },

    course: {
      baseUrl(id) {
        let url = `${window.api_url}/public/organizations/${window.organization_id}/courses`;
        if (id) url += `/${id}`;
        return url;
      },

      get(id, success) {
        if (success) {
          return axios.get(this.baseUrl(id)).then(success);
        }

        return axios.get(this.baseUrl(id));
      },

      index(success) {
        if (success) {
          return axios.get(this.baseUrl()).then(success);
        }

        return axios.get(this.baseUrl());
      },
    },

    family_subsidy_program: familySubsidyProgram,

    funding_source: {
      baseUrl(id) {
        let url = `${window.api_url}/public/organizations/${window.organization_id}/funding_sources`;
        if (id) url += `/${id}`;
        return url;
      },

      get(id, success) {
        return axios.get(this.baseUrl(id)).then(success);
      },

      index(params, success) {
        return axios.get(this.baseUrl(), { params }).then(success);
      },
    },

    grant_program: grantProgram,

    lesson: {
      baseUrl(id) {
        let url = `${window.api_url}/public/organizations/${window.organization_id}/lessons`;
        if (id) url += `/${id}`;
        return url;
      },

      get(id, success) {
        if (success) {
          return axios.get(this.baseUrl(id)).then(success);
        }

        return axios.get(this.baseUrl(id));
      },

      index(params, success) {
        if (success) {
          return axios.get(this.baseUrl(), { params }).then(success);
        }

        return axios.get(this.baseUrl(), { params });
      },
    },

    program: {
      baseUrl(id) {
        let url = `${window.api_url}/public/organizations/${window.organization_id}/programs`;
        if (id) url += `/${id}`;
        return url;
      },

      get(id, params) {
        return axios.get(this.baseUrl(id), { params }).catch(handleError);
      },

      index(filters, success) {
        if (!success) return axios.get(this.baseUrl(), { params: filters }).catch(handleError);
        return axios.get(this.baseUrl(), { params: filters }).then(success);
      },
    },

    provider: {
      baseUrl(id) {
        let url = `${window.api_url}/public/organizations/${window.organization_id}/providers`;
        if (id) url += `/${id}`;
        return url;
      },

      get(id, success) {
        if (!success) return axios.get(this.baseUrl(id)).catch(handleError);
        return axios.get(this.baseUrl(id)).then(success);
      },

      index(params, success) {
        if (!success) return axios.get(this.baseUrl(), { params }).catch(handleError);
        return axios.get(this.baseUrl(), { params }).then(success);
      },
    },

    question: question,

    schema: {
      baseUrl(id) {
        let url = `${window.api_url}/public/organizations/${window.organization_id}/schemas`;
        if (id) url += `/${id}`;
        return url;
      },

      get(id, success) {
        if (success) {
          return axios.get(this.baseUrl(id)).then(success);
        }

        return axios.get(this.baseUrl(id)).catch(handleError);
      },

      index(success) {
        if (success) {
          return axios.get(this.baseUrl()).then(success);
        }

        return axios.get(this.baseUrl()).catch(handleError);
      },
    },

    slug: {
      base_url(organization_id) {
        return `${window.api_url}/public/organizations/${organization_id}/slugs`;
      },

      get(organization_id, encoded_url, success, failure) {
        axios
          .get(`${this.base_url(organization_id)}/${encoded_url}`)
          .then(success)
          .catch(failure);
      },
    },

    subsidy_program: subsidyProgram,

    termsAgreement: {
      downloadUrl(id, params) {
        const urlParams = new URLSearchParams(params).toString();
        return `${window.api_url}/public/organizations/${window.organization_id}/terms_agreements/${id}.pdf?${urlParams}`;
      },
    },

    webpage: {
      get(id, success) {
        axios
          .get(`${window.api_url}/public/organizations/${window.organization_id}/webpages/${id}`)
          .then(success);
      },
    },
  },

  provider: {
    baseUrl(id) {
      let url = `${window.api_url}/public/organizations/${window.parent_organization_id || window.organization_id}/providers`;
      if (id) url = `${url}/${id}`;
      return url;
    },

    get(id, success) {
      if (!success) return axios.get(this.baseUrl(id));
      return axios.get(this.baseUrl(id)).then(success);
    },

    index(params, success) {
      if (!success) return axios.get(this.baseUrl(), { params });
      return axios.get(this.baseUrl(), { params }).then(success);
    },

    asset: {
      baseUrl(providerId) {
        return `${window.api_url}/public/providers/${providerId}/assets`;
      },

      index(providerId, filters, success) {
        if (success) {
          return axios.get(this.baseUrl(providerId), { params: filters }).then(success);
        }

        return axios.get(this.baseUrl(providerId), { params: filters });
      },
    },

    enrollment_steps: {
      baseUrl(providerId) {
        return `${window.api_url}/public/providers/${providerId}/enrollment_steps`;
      },

      index(providerId, success) {
        if (!success) return axios.get(this.baseUrl(providerId));
        return axios.get(this.baseUrl(providerId)).then(success);
      },
    },

    image: {
      base_url(provider_id) {
        return `${window.api_url}/public/providers/${provider_id}/images`;
      },

      index(provider_id, success) {
        axios.get(this.base_url(provider_id)).then(success);
      },
    },

    license: {
      base_url(providerId) {
        return `${window.api_url}/public/providers/${providerId}/license`;
      },

      get(providerId, success) {
        if (!success) return axios.get(this.base_url(providerId));
        return axios.get(this.base_url(providerId)).then(success);
      },
    },

    price: {
      base_url(providerId) {
        return `${window.api_url}/public/providers/${providerId}/prices`;
      },

      index(providerId, success) {
        axios.get(this.base_url(providerId)).then(success);
      },
    },

    staff_member: {
      base_url(providerId) {
        return `${window.api_url}/public/providers/${providerId}/staff_members`;
      },

      index(providerId, success) {
        axios.get(this.base_url(providerId)).then(success);
      },
    },

    tour_opening: {
      baseUrl(providerId) {
        return `${window.api_url}/public/providers/${providerId}/tour_openings`;
      },

      index(filters, id, success) {
        axios.get(this.baseUrl(id), { params: filters }).then(success);
      },
    },
  },

  site: {
    configuration: {
      get(success) {
        if (import.meta.env.VUE_ENABLE_MIRAGE === 'true') {
          /*
           * We have to return a stubbed config here since the MirageJS server hasn't been
           * started by the time this API call is made.
           */
          const configuration = require('../../server/configuration/specialist.json');
          return success({ data: configuration });
        }
        const siteUrl = (window.VUE_APP_CONFIG_URL || import.meta.env.VUE_APP_CONFIG_URL).replace(
          '/configuration',
          '/site',
        );
        return axios.get(siteUrl).then((siteResp) => {
          axios.get(`${siteResp.data.api_base_url}/public/configuration`).then(success);
        });
      },
    },
  },
};
