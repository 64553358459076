<template>
  <v-card
    border
    tile
  >
    <StaticMap
      :height="250"
      :latitude="provider.latitude"
      :longitude="provider.longitude"
      :width="382"
    />

    <v-card-title class="fs-20 fw-800">
      {{ $t('Contact information') }}
    </v-card-title>

    <v-card-text class="fs-16 fw-400">
      <ListItem
        :text="provider.address1 + ', ' + provider.city"
        icon="location_on"
      />

      <ListItem
        v-if="smAndDownClass"
        :href="'tel:' + provider.phone"
        :text="provider.phone || $t('Phone not available')"
        icon="phone"
      />

      <ListItem
        v-if="!smAndDownClass"
        :text="provider.phone || $t('Phone not available')"
        icon="phone"
      />

      <ListItem
        :text="provider.email || $t('Email not available')"
        icon="mail_outline"
      />

      <ListItem
        :href="provider.website"
        :text="provider.website || $t('No website')"
        icon="laptop_chromebook"
      />

      <v-row v-if="provider.google_url || provider.facebook_url || provider.yelp_url">
        <v-col>
          <a
            v-if="provider.google_url"
            :href="provider.google_url"
            class="me-4"
            target="_blank"
          >
            <img
              :src="$a.url('icons.google_round')"
              class="h-24"
            />
          </a>

          <a
            v-if="provider.facebook_url"
            :href="provider.facebook_url"
            class="me-4"
            target="_blank"
          >
            <img
              :src="$a.url('icons.facebook_round')"
              class="h-24"
            />
          </a>

          <a
            v-if="provider.yelp_url"
            :href="provider.yelp_url"
            target="_blank"
          >
            <img
              :src="$a.url('icons.yelp_round')"
              class="h-24"
            />
          </a>
        </v-col>
      </v-row>

      <v-row
        v-for="(field, index) in $store.state.schemas[provider.schema_id].meta.contact.append"
        :key="index"
      >
        <CustomField
          :field="field"
          :model-value="provider"
          :readonly="true"
          :schema-id="provider.schema_id"
          :show-title="false"
          class="fs-16"
          mode="show"
          show-icon="true"
        />
      </v-row>

      <div
        v-if="provider.contact_name || provider.contact_email"
        class="c-black fw-600 fs-18 mb-2 mt-4"
      >
        {{ $t('Primary contact') }}
      </div>

      <ListItem
        v-if="provider.contact_name"
        :text="provider.contact_name"
        icon="person_pin"
      />

      <ListItem
        v-if="provider.contact_phone"
        :text="provider.contact_phone"
        icon="phone"
      />

      <ListItem
        v-if="provider.contact_email"
        :text="provider.contact_email"
        icon="mail_outline"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useDisplay } from 'vuetify';
import CustomField from '@/shared/components/CustomField.vue';
import StaticMap from '@/shared/components/provider/StaticMap.vue';
import ListItem from '@/shared/components/ListItem.vue';

defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const display = useDisplay();
const smAndDownClass = display.smAndDown;
</script>
