import axios from 'axios';
import { handleError } from '@/shared/services/api';

export default {
  baseUrl() {
    return `${window.api_url}/organization/form_custom_views`;
  },

  create(attributes, schemaId) {
    return axios
      .post(this.baseUrl(), {
        data: {
          type: 'custom_view',
          attributes,
          relationships: {
            content_template: {
              data: {
                type: 'schema_id',
                id: schemaId,
              },
            },
          },
        },
      })
      .catch(handleError);
  },

  destroy(id) {
    return axios.delete(`${this.baseUrl()}/${id}`);
  },

  index(filters) {
    return axios.get(this.baseUrl(), { params: filters });
  },

  get(id) {
    return axios.get(`${this.baseUrl()}/${id}`);
  },

  update(id, attributes, relationships) {
    return axios
      .patch(`${this.baseUrl()}/${id}`, {
        data: {
          id,
          type: 'custom_view',
          attributes,
          relationships,
        },
      })
      .catch(handleError);
  },
};
